$theme: core;
@use "design-system" as ds;

.container {
  padding-bottom: ds.spacing(regular);
  @include ds.card($theme: $theme);
  @include ds.background(content-display, $namespace: ads, $theme: $theme);
  @include ds.text-color(primary, $theme: $theme);
}

.title {
  margin: 8px 0 0 0;
  @include ds.typography(h3, $theme);
  font-family: arial, sans-serif;
  line-height: 1.2;
  padding: 0 16px;
  @include ds.text-color(primary, $theme: $theme);
}

.text {
  margin: 4px 0 0 0;
  line-height: 1.2;
  padding: 0 16px;
  font-size: 16px;
  font-family: georgia, serif;
  @include ds.text-color(primary, $theme: $theme);
}

.footer {
  margin: 8px 0 0 0;
  line-height: 1.2;
  padding: 0 16px;
  font-size: 13px;
  font-family: arial, sans-serif;
  font-weight: bold;
  @include ds.text-color(primary, $theme: $theme);
}

.label {
  @include ds.typography(underline, $theme, $responsive: false);
  @include ds.text-color(primary, $theme: $theme);
  background-color: transparent;
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  font-weight: bold;
  padding: 6px 16px 5px 16px;
  align-items: center;
}

.button {
  padding: 0;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.teaser {
  margin-top: 0;
}
