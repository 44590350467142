$theme: core;
@use "design-system" as ds;

.tabs {
  display: flex;
  flex-direction: row;
}

.tab {
  @include ds.typography(button-text-2, $theme: $theme);
  border-width: 1px;
  border-left-width: 0;
  border-style: solid;
  background-color: transparent;
  padding: 10px ds.spacing(medium);
  cursor: pointer;
  @include ds.border(element, $a: 1px, $theme: $theme);
  @include ds.text-color(primary, $theme: $theme);
  transition:
    border-color 0.2s,
    background-color 0.2s,
    color 0.2s;

  flex-grow: 1;
  @media (min-width: ds.$screen-size--small) {
    flex-grow: 0;
  }

  &:first-child {
    border-left-width: 1px;
    border-top-left-radius: 100vi;
    border-bottom-left-radius: 100vi;
  }

  &:last-child {
    border-top-right-radius: 100vi;
    border-bottom-right-radius: 100vi;
  }

  &[data-active-item] {
    @include ds.typography(button-text, $theme: $theme);
    @include ds.element-background(element, $theme: $theme);
    @include ds.text-color(tertiary, $theme: $theme);
  }
}
