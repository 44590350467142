$theme: core;
@use "design-system" as ds;

.headerContainer {
  display: flex;
  flex-direction: column;

  &.regular {
    padding-bottom: ds.spacing(base);
  }

  &.native {
    padding-bottom: ds.spacing(base);
  }

  &.premium {
    &.smallCluster {
      text-align: left;
      margin: 0;
      gap: 0;
    }
  }

  &.smallCluster {
    display: flex;
    flex-direction: column;
    padding-bottom: ds.spacing(base);
  }

  &.box {
    margin: ds.spacing(medium) ds.spacing(regular-increased)
      ds.spacing(regular-increased) ds.spacing(regular-increased);
    gap: ds.spacing(base);
    text-align: center;
  }
}

.overline {
  order: 1;
  @include ds.typography(kicker, $theme);
  text-transform: uppercase;
  @include ds.text-color(brand, $theme: $theme);

  &.premium {
    @include ds.text-color(premium, $theme);

    @if $theme == ekonomi {
      @include ds.text-color(brand, $theme: $theme);
    }
  }
}

.title {
  order: 2;
  @include ds.typography(premium-h3, $theme);
  @include ds.text-color(brand, $theme: $theme);

  &.premium {
    @include ds.text-color(premium, $theme);

    @if $theme == ekonomi {
      @include ds.text-color(brand, $theme: $theme);
    }
  }
}

.intro {
  order: 3;
  @include ds.typography(h7, $theme);
  @include ds.text-color(secondary, $theme: $theme);
  text-wrap: balance;
}
