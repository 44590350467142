$theme: core;
@use "design-system" as ds;

.teaserTitle {
  @include ds.typography(h2, $theme);
  @include ds.text-color(primary, $theme: $theme);
  @include ds.stack(none);
  margin: 0;
  flex-grow: 0;
  text-wrap: balance;

  &.large {
    @include ds.typography(h1, $theme);
  }
}

.titleVignette {
  margin: 0;
  text-wrap: balance;
  padding-right: ds.spacing("micro");

  @include ds.text-color(brand, $theme: $theme);
  &.premium {
    @include ds.text-color(premium, $theme: $theme);
  }
}
