$theme: core;
@use "design-system" as ds;
$small-teaser-width: 100px;

.teaserImage {
  &.small {
    flex-grow: 0;
    flex-shrink: 0;
    width: $small-teaser-width;

    &.videoContainer {
      position: relative;
    }

    & img {
      border-radius: ds.$border-radius--default;
      width: 100%;
      height: auto;
      display: block;

      &.videoIcon {
        position: absolute;
        width: 40px;
        top: 45px;
        left: 7px;
      }
    }

    @media (min-width: ds.$screen-size--small) {
      width: round($small-teaser-width * ds.$desktop-upscaling-factor);
    }
  }

  &.large {
    display: block;
    margin-bottom: ds.spacing();

    & img {
      width: 100%;
      height: auto;
      display: block;
      border-radius: 0;
    }

    &.premium {
      padding: 0 ds.spacing();

      & img {
        border-radius: ds.$border-radius--default;
      }
    }
  }
}
