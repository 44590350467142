$theme: core;
@use "design-system" as ds;

.teaserFooter {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;

  &.timestamp {
    @include ds.text-color(secondary--prominent, $theme: $theme);
  }
  &.regular {
    @include ds.typography(underline, $theme);
  }
  &.premium {
    & span {
      @include ds.text-color(premium, $theme: $theme);
    }
    @include ds.typography(underline-2, $theme);
  }
}
