$theme: core;
@use "design-system" as ds;

.teaserText {
  @include ds.text-color(primary, $theme: $theme);
  @include ds.typography(body, $theme);
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  /* Break words when too long, preventing paragraph overflow */
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for webkit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
